
.heading-container-services {
    h2 {
    margin-right: 20px; /* Adjust space between text and line */
    white-space: nowrap;
  
        @media screen and (max-width: 768px) {
        white-space: normal;
        padding-bottom: 16px;
        margin-right: 0;
        width: 100%;
        border-bottom: 1px solid pink;
        }
    }
}