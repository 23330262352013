#chlAccordion {
  .accordion-main-header {
    background-color: $pf-dark-gray;
    color: $pf-white;
    font-family: "owners", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.04em;
    padding: 27px 30px;
    text-transform: none;
  }
  .accordion-item {
    background: rgba(255, 255, 255, 0.75);
    border: none;
    border-radius: 0;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
    &.has-black-background-color {
      background: rgba(0, 0, 0, 0.75);
    }
    &.has-dark-gray-background-color {
      background: rgba(51, 51, 51, 0.75);
    }
    &.has-medium-gray-background-color {
      background: rgba(109, 110, 112, 0.75);
    }
    &.has-light-gray-background-color {
      background: rgba(242, 244, 237, 0.75);
    }
    &.has-white-background-color {
      background: rgba(255, 255, 255, 0.75);
    }
    &.has-purple-background-color {
      background: rgba(110, 100, 249, 0.75);
    }
    &.has-yellow-background-color {
      background: rgba(255, 182, 54, 0.75);
    }
    &.has-orange-background-color {
      background: rgba(255, 127, 81, 0.75);
    }
    &.has-green-background-color {
      background: rgba(113, 198, 158, 0.75);
    }
    .accordion-header {
      color: $pf-dark-gray;
      position: relative;
      margin-top: 0px;
      &:after {
        content: '';
        width: 70px;
        height: 100%;
        background: rgba(51,51,51,.15);
        top: 0;
        right: 0px;
        display: block;
        position: absolute;
        z-index: 0;
      }
      .accordion-button {
        // background: rgba(255, 255, 255, 0.75);
        background: transparent;
        color: $pf-dark-gray;
        font-family: "owners", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 19px;
        line-height: 24px;
        letter-spacing: 0.04em;
        padding: 20px 14px 20px 50px;
        border-bottom: 1px solid $pf-medium-gray;
        border-radius: 0;
        &:after {
          background-image: url('../block_templates/images/icons/accordion_arrow_dark.svg');
          width: 43px;
          height: 23px;
          background-size: 43px 23px;
          z-index: 1;
        }
      }
    }
    .accordion-collapse {
      border-radius: 0;
      .accordion-body {
        background-color: $pf-white;
        padding: 30px;
        border-radius: 0;
      }
    }
  }
}
