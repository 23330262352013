.container-fluid {
  padding: 0px !important;
  overflow: hidden;
}
.container-fluid .hero {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
  background-position: center center;
}
.container-fluid .simple-hero img {
  width: 100%;
}

#chlAccordion .accordion-main-header {
  background-color: #333333;
  color: #ffffff;
  font-family: "owners", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.04em;
  padding: 27px 30px;
  text-transform: none;
}
#chlAccordion .accordion-item {
  background: rgba(255, 255, 255, 0.75);
  border: none;
  border-radius: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
}
#chlAccordion .accordion-item.has-black-background-color {
  background: rgba(0, 0, 0, 0.75);
}
#chlAccordion .accordion-item.has-dark-gray-background-color {
  background: rgba(51, 51, 51, 0.75);
}
#chlAccordion .accordion-item.has-medium-gray-background-color {
  background: rgba(109, 110, 112, 0.75);
}
#chlAccordion .accordion-item.has-light-gray-background-color {
  background: rgba(242, 244, 237, 0.75);
}
#chlAccordion .accordion-item.has-white-background-color {
  background: rgba(255, 255, 255, 0.75);
}
#chlAccordion .accordion-item.has-purple-background-color {
  background: rgba(110, 100, 249, 0.75);
}
#chlAccordion .accordion-item.has-yellow-background-color {
  background: rgba(255, 182, 54, 0.75);
}
#chlAccordion .accordion-item.has-orange-background-color {
  background: rgba(255, 127, 81, 0.75);
}
#chlAccordion .accordion-item.has-green-background-color {
  background: rgba(113, 198, 158, 0.75);
}
#chlAccordion .accordion-item .accordion-header {
  color: #333333;
  position: relative;
  margin-top: 0px;
}
#chlAccordion .accordion-item .accordion-header:after {
  content: "";
  width: 70px;
  height: 100%;
  background: rgba(51, 51, 51, 0.15);
  top: 0;
  right: 0px;
  display: block;
  position: absolute;
  z-index: 0;
}
#chlAccordion .accordion-item .accordion-header .accordion-button {
  background: transparent;
  color: #333333;
  font-family: "owners", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.04em;
  padding: 20px 14px 20px 50px;
  border-bottom: 1px solid #6D6E70;
  border-radius: 0;
}
#chlAccordion .accordion-item .accordion-header .accordion-button:after {
  background-image: url("../block_templates/images/icons/accordion_arrow_dark.svg");
  width: 43px;
  height: 23px;
  background-size: 43px 23px;
  z-index: 1;
}
#chlAccordion .accordion-item .accordion-collapse {
  border-radius: 0;
}
#chlAccordion .accordion-item .accordion-collapse .accordion-body {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 0;
}

.cta-wrapper .headline {
  font-family: "Owners";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 0.03em;
  color: #333333;
}
.cta-wrapper .content-wrapper {
  width: 100%;
}

.heading-container-services h2 {
  margin-right: 20px; /* Adjust space between text and line */
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .heading-container-services h2 {
    white-space: normal;
    padding-bottom: 16px;
    margin-right: 0;
    width: 100%;
    border-bottom: 1px solid pink;
  }
}

img {
  max-width: 100%;
}

.has-black-background-color {
  background-color: #000000;
}

.has-white-background-color {
  background-color: #ffffff;
}

.has-red-background-color {
  background-color: #ec1c24;
}

.has-blue-background-color {
  background-color: #007599;
}

.has-light-grey-background-color {
  background-color: #f3f3f3;
}

.has-dark-grey-background-color {
  background-color: #737373;
}